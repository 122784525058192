<c-sidebar #aside1="cSidebar" class="d-print-none hide border-start" colorScheme="light" id="aside1" overlaid
  placement="end" size="xl" visible>
  <!--aside-header -->
  <c-sidebar-header class="bg-transparent position-relative">
    <!--aside toggler-->
    <button cButtonClose class="float-end end-0 my-0 top-50 position-absolute translate-middle" aria-label="Close aside"
      (click)="closeSideModal()"></button>
  </c-sidebar-header>
  <div class="full-height-scrollable">
    <router-outlet></router-outlet>
  </div>
</c-sidebar>

<div class="modal-backdrop"></div>