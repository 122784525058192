import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { AlertComponent, AlertModule, ProgressBarComponent, ProgressBarDirective, ProgressComponent, ToastBodyComponent, ToastCloseDirective, ToastComponent, ToasterService, ToastHeaderComponent } from '@coreui/angular-pro';

@Component({
  selector: 'ow-toast-alert',
  standalone: true,
  imports: [ToastComponent, ToastHeaderComponent, ToastBodyComponent, ToastCloseDirective],
  templateUrl: './ow-toast-alert.component.html',
  styleUrls: ['./ow-toast-alert.component.scss'],
  providers: [{ provide: ToastComponent, useExisting: forwardRef(() => ToastAlertComponent) }]
})
export class ToastAlertComponent extends ToastComponent {

  @Input() closeButton = true;
  @Input() message = '';

  constructor(
    public override hostElement: ElementRef,
    public override renderer: Renderer2,
    public override toasterService: ToasterService,
    public override changeDetectorRef: ChangeDetectorRef
  ) {
    super(hostElement, renderer, toasterService, changeDetectorRef);
  }
}