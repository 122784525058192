import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import { IconDirective } from '@coreui/icons-angular';
import {
  ContainerComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular-pro';

import { SecureFooterComponent, SecureHeaderComponent, SecureAsideComponent } from '.';
import { AuthService } from '../../authentication/authentication.service';
import { IExtendedNavData, navItems } from './_nav';


function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './secure-layout.component.html',
  styleUrls: ['./secure-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    SecureHeaderComponent, 
    SecureAsideComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    SecureFooterComponent
  ]
})
export class SecureLayoutComponent implements OnInit{

  constructor(private _authService:AuthService, private router: Router) {}

  ngOnInit() {
    this.loadMenuItems(navItems); 
  }

  public navItems: IExtendedNavData[]|undefined;

  onScrollbarUpdate($event: any) {
    // if ($event.verticalUsed) {
    // console.log('verticalUsed', $event.verticalUsed);
    // }
  }

  async loadMenuItems(navItems: IExtendedNavData[]){
    const currentUser = await this._authService.getCurrentUser();
    this.navItems = navItems.filter((navItem: IExtendedNavData)=>{
        return !navItem.roles || this._authService.checkUserAccess(currentUser, navItem.roles);
    });
  }
}
