import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthHeaderInterceptor } from './authentication/auth-header.interceptor';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions
} from '@angular/router';

import { SidebarModule } from '@coreui/angular-pro';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';

//Store Effects 
import { mainEffects } from './store/main/effects'
import { paymentEffects } from './store/payment/effects'
import { uiEffects } from './store/ui/effects'
import { scheduleEffects } from './store/schedule/effects'

//Store Redusers
import { schedulingReducer } from './store/schedule/reducers/schedule.reducer';
import { paymentReducer } from './store/payment/reducers/payroll.reducer';
import { mainReducer } from './store/main/reducers/main.reducer';
import { uiReducer } from './store/ui/reducers/ui.reducer'
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects'
import { AuthGuard } from './authentication/auth.guard';
import { Title } from 'chart.js';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()
    ),
    importProvidersFrom(SidebarModule),
    IconSetService,
    AuthGuard,
    provideAnimations(),
    provideStore(),
    provideState({ name: 'main', reducer: mainReducer }),
    provideState({ name: 'payment', reducer: paymentReducer }),
    provideState({ name: 'schedule', reducer: schedulingReducer }),
    provideState({ name: 'ui', reducer: uiReducer }),
    provideEffects([...mainEffects, ...paymentEffects, ...scheduleEffects, ...uiEffects]),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true
    },
    provideCharts(withDefaultRegisterables())

  ]
};



