import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ButtonCloseDirective, SidebarBrandComponent, SidebarComponent, SidebarHeaderComponent, SidebarToggleDirective, ThemeDirective } from '@coreui/angular-pro';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-secure-aside',
  templateUrl: './secure-aside.component.html',
  styleUrls: ['./secure-aside.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    SidebarComponent, SidebarBrandComponent, SidebarHeaderComponent, RouterLink, ThemeDirective, ButtonCloseDirective, SidebarToggleDirective]
})
export class SecureAsideComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private store: Store<AppState>
  ) {}

  public messages = Array.from({ length: 5 }, (v, i) => i);

  ngAfterViewInit(): void {
    this.renderer.removeStyle(this.elementRef.nativeElement, 'display');
  }

  closeSideModal(){
    this.router.navigate([{ outlets: { modal: null } }], {
      relativeTo: this.router.routerState.root.firstChild,
      queryParamsHandling: 'preserve',
    });
  }
}
