<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed bg-dark-gradient border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
      <svg [class]="{icon: false}" cIcon class="sidebar-brand-full" height="40" name="logo" title="CoreUI Logo"></svg>
      <svg cIcon class="sidebar-brand-narrow" height="40" name="signet" title="Smarter Trucking"></svg>
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" compact />
  </ng-scrollbar>
  @if (!sidebar1.narrow) {
    <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex" toggle="unfoldable" style="cursor: pointer;">
      <button cSidebarToggler aria-label="Toggle sidebar fold"></button>
    </c-sidebar-footer>
  }
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-secure-header
    [cShadowOnScroll]="'sm'"
    class="mb-2 d-print-none header header-sticky p-0 shadow-sm"
    position="sticky"
    sidebarId="sidebar1"
  />
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-2">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-secure-footer />
</div>

<!--aside-->
<router-outlet name="modal"></router-outlet>

<!-- <app-secure-aside style="display: none;" /> -->
