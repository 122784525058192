import { NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import {
  BreadcrumbRouterComponent,
  ContainerComponent,
  HeaderComponent
} from '@coreui/angular-pro';

import { IconDirective } from '@coreui/icons-angular';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  standalone: true,
  imports: [ContainerComponent, IconDirective, RouterLink, RouterLinkActive, NgTemplateOutlet, BreadcrumbRouterComponent]
})
export class DefaultHeaderComponent extends HeaderComponent {

  constructor() {
    super();
  }

}
