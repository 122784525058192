<!--<c-footer>-->
  <div>
    <a href="https://www.smarter-trucking.com" target="_blank">Smarter Trucking</a>
    <span> &copy; 2025 Orangewall LLC</span>
  </div>
  <!-- <div class="ms-auto">
    Powered by
    <a href="https://www._____" target="_blank">
      <span> Company name</span>
    </a>
  </div> -->
<!--</c-footer>-->
