/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

import { Amplify } from 'aws-amplify';

import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
