import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import * as PayrollActions from '../actions/payroll.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PaymentsService } from '../services/payroll.service';
import { GeneratePayrollOptions, Payroll } from '../models/payroll.model';
import * as UiActions from 'src/app/store/ui/actions/ui.actions';


@Injectable()
export class PayrollEffects {
    constructor(private actions$: Actions, private paymentService: PaymentsService, private store: Store<AppState>) { }

    loadPayrolls$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.loadPayrolls),
        mergeMap(({ organizationId, clientId }) => this.paymentService.getPayrolls(organizationId, clientId).pipe(
            map((payrolls: Payroll[]) => {
                return PayrollActions.loadPayrollsSuccess({ payrolls });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    loadPayrollById$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.loadPayrollByID),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.getPayrollById(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.loadPayrollByIDSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    getGeneratePayrollOptions$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.getGeneratePayrollOptions),
        withLatestFrom(this.store.select(state => state.payment.generatePayrollOptions)),
        filter(([action, generatePayrollOptions]) => !generatePayrollOptions), // only continue if it was not loaded before
        mergeMap(([action, generatePayrollOptions]) => {
            const { organizationId, clientId } = action; // Extract the clientId and organizationId from the action

            return this.paymentService.getGeneratePayrollOptions(organizationId, clientId).pipe(
                map((generatePayrollOptions: GeneratePayrollOptions) => {
                    return PayrollActions.getGeneratePayrollOptionsSuccess({ generatePayrollOptions });
                }),
                catchError(error => {
                    return of(UiActions.serviceCallFailure({ action: PayrollActions.getGeneratePayrollOptions, error }))
                })
            )
        })
    )
    );

    generatePayroll$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.generatePayroll),
        mergeMap(({ organizationId, clientId, payrollType }) => this.paymentService.generatePayroll(organizationId, clientId, payrollType).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.generatePayrollSuccess({ payroll });
            }),
            catchError(error => {
                return of(UiActions.serviceCallFailure({ action: PayrollActions.generatePayroll, error }))
            })
        )
        )
    )
    );


    generatePaystubs$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.generatePaystubs),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.generatePaystubs(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                console.log('generate paystubs effect triggered')
                return PayrollActions.generatePaystubsSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    enablePayableTripsEdit$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.enablePayableTripsEdit),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.enablePayableTripsEdit(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.enablePayableTripsEditSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );
}
