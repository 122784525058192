import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular-pro';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-secure-footer',
  templateUrl: './secure-footer.component.html',
  standalone: true
})
export class SecureFooterComponent extends FooterComponent {
  constructor() {
    super();
  }

  envName: string = environment.envName

}
