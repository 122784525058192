import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import * as FeatureFlagsActions from '../actions/feature-flags.actions';
import {FeatureFlagsService} from '../services/feature-flags.service'
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { ClientFeatureFlags, FeatureFlags } from '../models/model';

@Injectable()
export class FeatureFlagsEffects {
    constructor(private actions$: Actions, private featureFlagsService: FeatureFlagsService, private store: Store<AppState>) { }

    loadFeatureFlags$ = createEffect(() => this.actions$.pipe(
        ofType(FeatureFlagsActions.loadFeatureFlags),
        mergeMap(({ organizationId, clientId }) => this.featureFlagsService.getFeatureFlags(organizationId, clientId).pipe(
            map((featureFlags: FeatureFlags) => {
                return FeatureFlagsActions.loadFeatureFlagsSuccess({ clientId, featureFlags });
            }),
            catchError(error => of( /* handle error action */))
        ))
    ));
}
