import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageType, NotificationMessage } from 'src/app/store/ui/models/model';
import { ToastAlertComponent } from 'src/app/views/components/ow-toast-alert/ow-toast-alert.component';
import {
  ToasterComponent,
  ToasterPlacement
} from '@coreui/angular-pro';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';


@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    ToasterComponent
  ]
})
export class EmptyLayoutComponent implements OnInit {


  @ViewChild(ToasterComponent) toaster!: ToasterComponent;


  constructor(private store: Store<AppState>) {

  }

  ngOnInit() {
    // Subscribe to notifications for whole application
    const notification$ = this.store.select(state => state.ui.notification);
    notification$.subscribe(
      {
        next: (notificationMessage) => {
          if (notificationMessage) {
            this.addToast(notificationMessage)
          }
        },
        error: err => {
          console.log(err);
          const notification = new NotificationMessage()
          notification.message = 'Something went wrong! Please try again!'
          notification.messageType = MessageType.Error;
          this.addToast(notification);
        }
      }
    )
  }


  addToast(notification: NotificationMessage) {
    const options = {
      // title: `CoreUI for Angular Toast`,
      message: notification.message,
      delay: 8000,
      placement: ToasterPlacement.TopEnd,
      color: notification.messageType,
      autohide: true,
    }
    const componentRef = this.toaster.addToast(ToastAlertComponent, { ...options });
  }
}
