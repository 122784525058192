import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { from, lastValueFrom } from 'rxjs';
import { AuthService } from './authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private _authenticationService: AuthService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //If the http request is not to our backend api, don't add token
    // console.log('intercepting ...')
    console.log(request.url)
    console.log(request.url.indexOf(environment.apiEndpoint) === -1)
    if (request.url.indexOf(environment.apiEndpoint) === -1 || request.url.indexOf(environment.apiUnauthenticatedEndpoint) >-1) {
      return next.handle(request);
    }
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await this._authenticationService.getJwtToken();
    let changedRequest = request;
    // HttpHeader object immutable - copy values
    const headerSettings:any = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (token) {
      headerSettings['Authorization'] = token; // 'Bearer ' + token;
    }

    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = request.clone({ headers: newHeader });
    // console.log(changedRequest);
    return await lastValueFrom(next.handle(changedRequest));
  }
}