<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true" class="px-4">
    <button [cSidebarToggle]="sidebarId()" cHeaderToggler class="btn" toggle="visible"
      style="margin-inline-start: -14px;" aria-label="Toggle sidebar navigation">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-breadcrumb-router class="ms-2 d-none d-lg-flex me-auto"></c-breadcrumb-router>

    <!-- This is a generic toaster to work from everywhere -->
    <c-toaster class="p-3" position="fixed"></c-toaster>

    <c-header-nav class="ms-3 me-sm-4">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

    <!--aside toggler-->
    <!-- <button
      cHeaderToggler
      cSidebarToggle="aside1"
      style="margin-inline-end: -12px"
      toggle="visible"
    >
      <svg cIcon name="cilApplicationsSettings" size="lg"></svg>
    </button> -->
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <a href [caret]="false" cDropdownToggle class="py-0">
      Manage
    </a>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a routerLink="/secure/manage/vehicles" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Vehicles
        </a>
      </li>
      <li>
        <a routerLink="/secure/manage/employees" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Employees
        </a>
      </li>
      <li>
        <a routerLink="/secure/manage/driver-reported-trips" cDropdownItem>
          <svg cIcon class="me-2" name="cil-user"></svg>
          Driver Reported Trips
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="/secure/manage/settings/user-profile" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a routerLink="/secure/manage/settings/client-settings" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      @if (userRoles && userRoles.indexOf('demo-admin') > -1) {
      <li>
        <h6 cDropdownHeader class="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">Demo Setup</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="/secure/manage/demo/upload-fedex-settlement" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Setup Fedex Settlement
        </a>
      </li>
      }
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a href="#" (click)="logOut()" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log out
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>